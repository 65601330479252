export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'sobreDestinatario.update',
      {
        values: {
          idsobre_destinatario: id,
          email: formData.email,
          telefono: formData.telefono,
          nombre: formData.nombre,
          nif: formData.nif,
          clasificacion: formData.clasificacion,
          notifica_sat: formData.notifica_sat,
          notifica_comercial: formData.notifica_comercial,
          notifica_admon: formData.notifica_admon,
        },
      },
    )
  },
}
